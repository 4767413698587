
<template>
  <div class="manage-project-sales-reservation-detail">
    <div class="container fluid">
      <reservation-detail
        :reservationId="$route.params.id"
        :getReservationDetailAPI="reservationAPI.getReservationDetail"
        :approveReservationAPI="reservationAPI.approveReservation"
        :cancelReservationAPI="reservationAPI.cancelReservation"
        listingRouteName="ManageProjectReservations"
      ></reservation-detail>
    </div>
  </div>
</template>

<script>
import ReservationDetail from "@/components/GlobalComponents/Shared/ProjectSales/Reservation/ReservationDetail";
import { projectReservation as reservationAPI } from "@/api";

export default {
  components: {
    ReservationDetail
  },
  mixins: [],
  props: {
    isAssigned: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return { reservationAPI, API: {} };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss">
</style>
